<script setup lang="ts">
/**
 * @author Luka Tchigladze, Beka Chkhaidze
 */
import { onMounted } from "vue";
import { computed } from "vue";

// PROPS
const props = withDefaults(
  defineProps<{
    link: {
      title: string;
      url: string;
      url_target: string;
      targetblank?: boolean;
      full_url?: string;
    };
    label?: string;
  }>(),
  {
    label: "Link",
  }
);

const appProtocolLinks = ["tel", "viber", "mailto", "whatsapp"];

const formated = computed(() => {
  let url: string;
  let isBlank =
    ["1", 1, "_blank"].includes(props.link?.url_target) ||
    props.link?.targetblank ||
    props.link?.url?.startsWith("http");

  if (typeof props.link === "string") {
    url = props.link;
    isBlank = url?.startsWith("http");
  } else {
    url = props.link?.url || props.link?.full_url || "#";
  }

  if (!url) {
    url = "#";
  } else if (isBlank && !url?.startsWith("http")) {
    url = "http://" + url;
  }

  const includesProtoLinks = appProtocolLinks.some((el) => {
    const url = props.link?.url?.replace(/^\/(ka|ru|en)\//, "");
    const res = url?.startsWith(el);
    return res;
  });

  if (includesProtoLinks) {
    isBlank = false;
    url = props.link?.url?.replace(/^\/[a-z]{2}\//, "");
  }

  const target = isBlank ? "_blank" : "_self";

  return { isBlank, url, target, includesPorotoLinks: includesProtoLinks };
});

onMounted(() => {
  if (!props.link)
    console.warn(
      `\n\n[Link]: You forgot to pass ':link' props\nPlease check README.md\n\n`
    );
});
</script>

<template>
  <a
    data-Link
    v-if="formated.isBlank || formated.includesPorotoLinks"
    :href="formated?.url"
    :target="formated.target"
    :aria-label="label"
  >
    <slot />
  </a>
  <router-link
    data-Link
    v-else
    :aria-label="label"
    :to="formated?.url"
    :target="formated.target"
  >
    <slot />
  </router-link>
</template>
